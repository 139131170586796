<template>
    <div class="pt-5 px-3">
        <!-- curr_user_signature_url=
      {{ curr_user_signature_url }} -->
        <div class="d-flex justify-content-center">
            <table class="table table-bordered table-responsive text-start d-table">
                <tr class="w-100">
                    <td class="p-1">
                        <b-img class="adjustWidth" :src="require('@/assets/images/logo/logo_mtca.png')" alt="logo" />
                    </td>
                    <td class="p-1">
                        <h4 class="">CHECKLIST</h4>
                        <h5>{{$t('demand_detail.committee')}}</h5>
                    </td>
                    <td class="p-1">
                        <h6 class=""><strong>Reference:</strong> {{ reference }}</h6>
                        <h6><strong>{{$t('demand_detail.requestor')}}</strong> {{ checklist_object.identity }}</h6>
                    </td>
                </tr>
            </table>
        </div>
        <div class="d-flex justify-content-center pt-2 px-2">
            <b-row>
                <b-col cols="12">
                    <b-form-checkbox class="mb-1" name="verif_doc" v-model="checklist_object.verif_doc" @change="() => {
                            $emit('on_change_checklist_object', checklist_object);
                        }
                        ">
                        <h4>{{$t('demand_detail.checking')}}</h4>
                    </b-form-checkbox>
                </b-col>
                <b-col cols="12" class="mb-2">
                    <h4><strong>Observations:</strong></h4>
                    <b-form-textarea rows="2" v-model="checklist_object.verif_observ" @change="() => {
                            $emit('on_change_checklist_object', checklist_object);
                        }
                        " />
                </b-col>

                <b-col cols="12">
                    <b-form-checkbox class="mb-1" name="traitement_doc" v-model="checklist_object.traitement_doc" @change="() => {
                            $emit('on_change_checklist_object', checklist_object);
                        }
                        ">
                        <h4>{{$t('demand_detail.treatment')}}</h4>
                    </b-form-checkbox>
                </b-col>
                <b-col cols="12" class="mb-2">
                    <h4><strong>Observations:</strong></h4>
                    <b-form-textarea rows="2" v-model="checklist_object.traitement_observ" @change="() => {
                            $emit('on_change_checklist_object', checklist_object);
                        }
                        " />
                </b-col>

                <b-col cols="12">
                    <b-form-checkbox class="mb-1" name="validation_doc" v-model="checklist_object.validation_doc" @change="() => {
                            $emit('on_change_checklist_object', checklist_object);
                        }
                        ">
                        <h4>{{$t('demand_detail.validation')}}</h4>
                    </b-form-checkbox>
                </b-col>
                <b-col cols="12" class="">
                    <h4><strong>Observations:</strong></h4>
                    <b-form-textarea rows="2" v-model="checklist_object.validation_observ" @change="() => {
                            $emit('on_change_checklist_object', checklist_object);
                        }
                        " />
                </b-col>
            </b-row>
        </div>
        <b-row class="mt-2 d-flex justify-content-end">
            <b-col cols="8 " class="d-flex justify-content-end">
                <h4 class="pb-5 text-center">
                    Signature <br />
                    <small>{{$t('demand_detail.visa')}}</small>
                    <!-- border: none -->
                    <iframe :src="curr_user_signature_url ? curr_user_signature_url : fileContent
                        " style="width: 100%; height: 24vh">
                        {{$t('demand_detail.oops')}}
                    </iframe>
                    <b-form-file v-if="!forPrint" class="mt-1" :placeholder="$t('demand_detail.signature')"
                        :drop-placeholder="$t('demand_detail.drag_drop')" accept="image/jpeg, image/jpg, application/pdf"
                        :state="!isValidSignatureFile(checklist_object.signature_file)
                                ? false
                                : null
                            " @change="handleFileChange" />
                    <b-form-input v-if="!forPrint" v-model="checklist_object.signature_nom_prenoms" class="col-12" @change="() => {
                            $emit('on_change_checklist_object', checklist_object);
                        }
                        " :placeholder="$t('demand_detail.fullname')" />
                    <span v-else>
                        {{ checklist_object.signature_nom_prenoms }}
                    </span>
                </h4>
            </b-col>
        </b-row>
    </div>
</template>
  
<script>
import {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    BImg,
    VBToggle,
    BBadge,
    BSpinner,
    BModal,
    BTable,
    BFormCheckbox,
    BFormTextarea,
    BFormFile,
    BFormInput,
} from "bootstrap-vue";
import statuses from "@/views/apps/formulaires/requests_states.js";
// import VueSignaturePad from "vue-signature-pad";
import localstorageService from "@/services/localstorage/localstorage.service";

export default {
    components: {
        BRow,
        BCol,
        BCard,
        BCardBody,
        BTableLite,
        BCardText,
        BButton,
        BAlert,
        BLink,
        BImg,
        BBadge,
        BSpinner,
        BModal,
        BTable,
        BFormCheckbox,
        BFormTextarea,
        BFormFile,
        BFormInput,
    },
    props: {
        data: {
            required: true,
            //   default: ()=>{{}}
        },
        forPrint: {
            required: false,
        },
        reference: {
            required: false,
            //   default: ()=>{{}}
        },
        // signature: {
        //   required: false,
        //   //   default: ()=>{{}}
        // },
    },
    data() {
        return {
            fileContent: null,
            curr_user_signature_object: null,
            curr_user_signature_url: null,
            user_data: null,
        };
    },
    computed: {
        checklist_object() {
            return (
                this.data || {
                    verif_doc: null,
                    verif_observ: null,
                    traitement_doc: null,
                    traitement_observ: null,
                    validation_doc: null,
                    validation_observ: null,
                    signature_file: null,
                    // signature_file_name: null,
                    signature_nom_prenoms: null,
                }
            );
        },
    },
    watch: {
        $route: {
            immediate: true,
            deep: false,
            handler(val, old) { },
        },
    },
    mounted() {
         
        if (!this.checklist_object.signature_nom_prenoms) {
            const userData = localstorageService.getUserData();
            this.checklist_object.signature_nom_prenoms = userData.fullname;
            this.$emit("on_change_checklist_object", {
                ...this.checklist_object,
                // signature_file: file,
            });
        }
        if (this.forPrint) {
            this.curr_user_signature_url = this.checklist_object.signature_file;
        } else {
            if (!this.checklist_object.signature_file) {
                //signature précédente absente
                this.curr_user_signature_object =
                    localstorageService.getUserSignature();
                // signature associée au profil
                if (this.curr_user_signature_object) {
                    this.curr_user_signature_url =
                        this.curr_user_signature_object.original_url;
                    this.$emit("on_change_checklist_object", {
                        ...this.checklist_object,
                        signature_file: this.curr_user_signature_object.id,
                    });
                }
            } else {
                //signature précédente présente
                this.curr_user_signature_url = this.checklist_object.signature_file;
            }
        }
    },
    methods: {
        status(code) {
            const status = this.statusMatching(code);
            return status;
        },
        // async load_curr_signature() {
        //   const fileObject = await this.downloadFileAndConvertToFileObject(
        //     this.curr_user_signature_url
        //   );
        //   if (fileObject) {
        //     this.checklist_object.signature_file = fileObject;
        //     this.checklist_object.signature_file_name = fileObject.name;
        //   }
        // },
        handleFileChange(event) {
            // Récupérer le fichier à partir de l'événement de changement
            const file = event.target.files[0];
            // this.checklist_object.signature_file = file;
            // this.checklist_object.signature_file_name = file.name;

            // Lire le contenu du fichier en utilisant FileReader
            const reader = new FileReader();
            reader.onload = (e) => {
                this.curr_user_signature_url = e.target.result;
            };
            reader.readAsDataURL(file);
            // this.$emit("on_change_checklist_object", this.checklist_object);
            this.$emit("on_change_checklist_object", {
                ...this.checklist_object,
                signature_file: file,
            });
        },
    },
};
</script>
<style scoped>
.adjustWidth {
    width: 100px;
    height: 30px;
}
</style>
  